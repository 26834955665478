import React from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import SocialMenu from "../components/socialmenu"
import PostLink from "../components/post-link"

const IndexPage = ({
  data: {
    allSitePage: { edges },
  },
  pageContext,
  location,
}) => {
  const Posts = edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })
  return (
    <Layout>
      <SEO title={pageContext.title} pathname={location.pathname} />
      <TwoCol
        left={
          <>
            <div className="content">
              <p className="is-size-3 has-text-centered">
                Welcome to wirehead.fm, the music of a
                keyboardist/guitarist/bassist/vocalist/hacker
              </p>
            </div>
            <div className="content">
              <h1>Recent updates:</h1>
              {Posts}
            </div>
          </>
        }
        right={
          <aside className="menu">
            <SocialMenu />
          </aside>
        }
      />
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  {
    allSitePage(
      sort: { fields: context___date, order: DESC }
      filter: { context: { date: { ne: null } } }
      limit: 5
    ) {
      edges {
        node {
          id
          path
          context {
            id
            name
            parent
            title
            type
            depth
            date
            excerpt
          }
        }
      }
    }
  }
`

export const frontmatter = {
  title: "Wirehead.fm",
}